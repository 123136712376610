<template>
  <div id="createWebMessagePage">
    <div class="loadingDiv" v-if="fetchingDataOnEditMode" v-loading="true" style="min-height: 300px"></div>

    <template v-else>
      <div class="mainHeader bg-white">
        <a @click="$router.push(message.status == 'running' ? `/webMessage/${message.id}/report` : '/webMessage/popup')" style="cursor: pointer">
          <i class="el-icon-back"></i>
        </a>
        &nbsp;
        <span class="campaignHeaderTitle">{{ this.message.name }}</span>

        <!-- Right Side Buttons -->
        <div style="float: right">
          <template v-if="message.status == 'draft'">
            <el-button size="small" type="danger" class="nextBtn" @click="deleteWebMessage" :loading="deletingCampaign" :disabled="savingCampaignAsDraft"> Delete Draft </el-button>
            <el-button size="small" type="info" class="nextBtn" @click="cloneWebMessage" :loading="savingCampaignAsDraft" :disabled="savingCampaignAsDraft"> Create Duplicate </el-button>
            <el-button size="small" class="nextBtn" type="success" :loading="launchingCampaign" :disabled="savingCampaignAsDraft || deletingCampaign" @click="onPublish"> Publish </el-button>
            <el-button size="small" type="primary" class="nextBtn" @click="onSaveChanges" :loading="savingCampaignAsDraft" :disabled="launchingCampaign || deletingCampaign"> Save Draft </el-button>
          </template>
          <template v-else>
            <el-button size="small" type="info" class="nextBtn" @click="cloneWebMessage" :loading="savingCampaignAsDraft" :disabled="savingCampaignAsDraft"> Create Duplicate </el-button>
            <el-button size="small" type="danger" class="nextBtn" @click="deleteWebMessage" :loading="deletingCampaign" :disabled="savingCampaignAsDraft"> Delete </el-button>
            <el-button size="small" type="primary" class="nextBtn" @click="onSaveChanges" :loading="savingCampaignAsDraft" :disabled="deletingCampaign"> Save Changes </el-button>
          </template>
        </div>
      </div>

      <!-- Campaign Design Step -->
      <div class="campaignStep designerStep" v-show="currentStep == 0" style="flex: 1; padding: 0px">
        <!-- Step Header -->
        <div class="campaignStepHeader">
          <span class="headerTitle"> Design </span>

          <el-button class="nextBtn" type="primary" size="mini" @click="showBehaviourStep()">
            Display Settings &nbsp;
            <i class="el-icon-arrow-right el-icon-right"></i>
          </el-button>

          <el-button class="nextBtn" type="success" size="mini" @click="showDesignPreview()"> <i class="el-icon-view"></i> &nbsp; Show Preview </el-button>

          <!-- Change Popup Template -->
          <WebPopupTemplatesPopup ref="webTemplatesPopup" @templateSelected="onPopupTemplateSelected"></WebPopupTemplatesPopup>
          <el-button class="nextBtn" type="success" size="mini" @click="showTemplatesDialog(true)"> Change Template </el-button>
        </div>

        <WebPopupBuilder ref="webPopupBuilder"></WebPopupBuilder>

        <!-- Message Preview -->
        <WebMessagePreviewPopup ref="webMessagePreview"></WebMessagePreviewPopup>
      </div>

      <!-- Campaign Behaviour Step -->
      <div class="campaignStep behaviourStep" v-show="currentStep == 1">
        <!-- Step Header -->
        <div class="campaignStepHeader">
          <el-button class="prevButton" type="warning" size="mini" @click="currentStep--"> <i class="el-icon-arrow-left el-icon-back"></i> &nbsp; Designer </el-button>
        </div>
        <br />
        <div class="behaviourCardsContainer">
          <el-row :gutter="25">
            <el-col :span="24">
              <!-- General - Campaign Info  -->
              <el-card class="box-card mb-4" shadow="never">
                <div slot="header" class="clearfix">
                  <span>General Settings</span>
                </div>
                <div class="cardItemBlock">
                  <div class="inputLabel">Popup Name</div>
                  <el-input type="text" style="width: 100%" v-model="message.name" />
                </div>
              </el-card>
            </el-col>

            <el-col :span="24">
              <!-- Behaviour - Target Audience  -->
              <el-card class="box-card behaviourCard" shadow="never">
                <div slot="header" class="clearfix">
                  <span>Select Target Audience</span>
                </div>
                <div class="cardItemBlock">
                  <div class="inputLabel">Display to visitors</div>
                  <el-radio-group v-model="behaviour.visitorType" size="small">
                    <el-radio label="all" border>Everyone</el-radio>
                    <el-radio label="new" border>New Visitors (First time visiting your site)</el-radio>
                    <el-radio label="returning" border>Returning Visitor (Has already visited site in past)</el-radio>
                  </el-radio-group>
                </div>

                <div v-if="showSegmentFilterTo.indexOf($store.state.token.companyId) > -1">
                  <el-divider></el-divider>
                  <div class="cardItemBlock" style="margin-bottom: 0px !important">
                    <div class="inputLabel">Additional Behaviour Filters</div>
                    <!-- Audiance Type Selector -->
                    <div class="existingSegmentCard">
                      <el-radio-group class="audianceSegmentOption" v-model="targetAudiance.audiance_type" size="small">
                        <el-radio label="all" border>No Filters</el-radio>
                        <el-radio label="segment" border>Customers In Segment </el-radio>
                        <el-radio label="custom" border>Custom Segment</el-radio>
                        <!-- <el-radio label="static_list" border>Customers In Static List</el-radio> -->
                      </el-radio-group>
                    </div>
                    <br />

                    <!-- Custom Audience Builder -->
                    <el-card class="box-card customSegmentCard" shadow="never" v-if="targetAudiance.audiance_type == 'custom'">
                      <UserPropertyFilterComponent v-bind:filterTitle="'  Behaviour Filters'" ref="userFilter" v-bind:formattedPropertyFilter="targetAudiance.filter"></UserPropertyFilterComponent>
                    </el-card>

                    <!-- Existing Segment Dropdown -->
                    <div class="existingSegmentCard" v-if="targetAudiance.audiance_type == 'segment'">
                      <div class="inputLabel">Select Segment</div>
                      <div class="form-title"></div>
                      <div class="form-dropdown">
                        <el-select style="width: 400px" v-model="targetAudiance.segment_id" filterable placeholder="Select Segment" size="small">
                          <el-option v-for="item in segmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Existing Static List Dropdown -->
                    <div class="existingSegmentCard" v-if="targetAudiance.audiance_type == 'static_list'">
                      <div class="inputLabel">Select Static List</div>
                      <div class="form-dropdown">
                        <el-select v-model="targetAudiance.static_segment_id" filterable placeholder="Select Static List" size="small">
                          <el-option v-for="item in staticSegmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Adding Behaviour filters may delay popup appearance upto 1.5 seconds -->
                    <div v-if="targetAudiance.audiance_type != 'all'" style="margin-top: 20px">
                      <el-alert title="Adding Behaviour filters may delay popup appearance upto 1.5 seconds." type="warning" :closable="false" show-icon style="display: inline"> </el-alert>
                    </div>
                  </div>
                </div>
              </el-card>
            </el-col>

            <!-- Behaviour - Device Visiblity  -->
            <el-col :span="24">
              <el-card class="box-card behaviourCard" shadow="never">
                <div slot="header" class="clearfix">
                  <span>Which all devices you want to show popup?</span>
                </div>
                <el-checkbox v-model="behaviour.deviceVisiblity.desktop" small border>Show On Desktop</el-checkbox>
                <el-checkbox v-model="behaviour.deviceVisiblity.mobile" small border>Show On Mobile</el-checkbox>
              </el-card>
            </el-col>

            <!-- Behaviour - Page List  -->
            <el-col :span="24">
              <div class="bg-white border rounded-md mb-10 overflow-hidden" shadow="never">
                <div class="p-2.5 bg-gray-50 border-b">
                  <span>Select Website Pages To Show</span>
                </div>

                <!-- Pages To Filter -->
                <div class="px-3 pt-3">
                  <div class="cardItemBlock">
                    <div class="inputLabel">Specify Pages</div>
                    <el-radio-group v-model="behaviour.page.type" size="small">
                      <el-radio label="all" value="all" border>All Pages</el-radio>
                      <el-radio label="specific" value="specific" border>Specific Pages</el-radio>
                    </el-radio-group>
                  </div>

                  <!-- Custom Page List Input -->
                  <div class="cardItemBlock pageListContainer" v-if="behaviour.page.type == 'specific'">
                    <div class="inputLabel">Specify List Of Pages</div>
                    <el-tag :key="index" v-for="(page, index) in behaviour.page.pageList" closable :disable-transitions="false" @close="removePage(index)"> {{ page.filterDisplayText.toUpperCase() + ': ' + page.value }} </el-tag>
                    <el-input placeholder="/category/mens" v-model="pageInput.value" class="input-with-select" style="width: 400px" size="small" @keyup.enter.native="onAddPage">
                      <el-select v-model="pageInput.filter" slot="prepend" placeholder="Select" style="width: 110px">
                        <el-option label="Contains" value="contains"></el-option>
                        <el-option label="Not Contains" value="not_contains"></el-option>
                        <el-option label="Equals" value="equal"></el-option>
                        <el-option label="Not Equals" value="not_equal"></el-option>
                        <el-option label="Starts With" value="starts_with"></el-option>
                        <el-option label="Ends With" value="ends_with"></el-option>
                      </el-select>
                      <el-button class="addPageButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddPage">Add</el-button>
                    </el-input>
                  </div>
                </div>

                <!-- Domains To Filter -->
                <div class="px-3 pt-3 border-t">
                  <div class="cardItemBlock">
                    <div class="inputLabel">Specify Domains</div>
                    <el-radio-group v-model="behaviour.domain.type" size="small">
                      <el-radio label="all" value="all" border>All Domains</el-radio>
                      <el-radio label="specific" value="specific" border>Specific Domains</el-radio>
                    </el-radio-group>
                  </div>

                  <!-- Custom Page List Input -->
                  <div class="cardItemBlock pageListContainer" v-if="behaviour.domain.type == 'specific'">
                    <div class="inputLabel">Specify List Of Domains</div>
                    <el-tag :key="index" v-for="(domain, index) in behaviour.domain.domainList" closable :disable-transitions="false" @close="removeDomain(index)"> {{ domain }} </el-tag>
                    <el-input placeholder="mywebsite.com" v-model="domainInput" class="input-with-select" style="width: 400px" size="small" @keyup.enter.native="onAddDomain">
                      <el-button class="addPageButton" slot="append" type="primary" size="small" icon="el-icon-plus" @click="onAddDomain">Add</el-button>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-col>

            <!-- Behaviour - When To Show  -->
            <el-col :span="12" v-if="this.campaignContent && this.campaignContent.json.appereance.type != 'embed'">
              <el-card class="box-card behaviourCard" shadow="never">
                <div slot="header" class="clearfix">
                  <span>When To Show</span>
                </div>

                <div class="cardItemBlock">
                  <div class="inputLabel">Specify when to show the poppup</div>
                  <el-radio-group v-model="behaviour.when.type" size="small">
                    <el-radio label="pageLoad" value="pageLoad" border>At Page Load</el-radio>
                    <el-radio label="pageExit" value="pageExit" border>At Page Exit</el-radio>
                    <el-radio label="javascript" value="javascript" border>Programtically (Using Javascript)</el-radio>
                  </el-radio-group>
                </div>

                <!-- Page Load Settings -->
                <div class="cardItemBlock javascriptContainer" v-if="behaviour.when.type == 'pageLoad'">
                  <div class="inputLabel">At Page Load - When To Show?</div>
                  <el-radio-group style="margin-bottom: 5px; margin-top: 5px" v-model="behaviour.when.pageLoad.showType" size="small">
                    <el-radio style="margin-bottom: 0px" label="timer" value="timer">Show after given time</el-radio>
                    <el-radio label="scroll" value="scroll">Shown when user scrolls</el-radio>
                  </el-radio-group>
                  <br />
                  <!-- Page Load Timer -->
                  <el-select v-if="behaviour.when.pageLoad.showType == 'timer'" v-model="behaviour.when.pageLoad.timer" size="small">
                    <el-option label="Show Immediately" value="0"></el-option>
                    <el-option label="After 3 seconds" value="3"></el-option>
                    <el-option label="After 5 seconds" value="5"></el-option>
                    <el-option label="After 10 seconds" value="10"></el-option>
                    <el-option label="After 15 seconds" value="15"></el-option>
                    <el-option label="After 30 seconds" value="30"></el-option>
                    <el-option label="After 1 minute" value="60"></el-option>
                    <el-option label="After 2 minutes" value="120"></el-option>
                    <el-option label="After 3 minutes" value="180"></el-option>
                    <el-option label="After 5 minutes" value="300"></el-option>
                  </el-select>

                  <!-- Page Load Timer -->
                  <el-select v-if="behaviour.when.pageLoad.showType == 'scroll'" v-model="behaviour.when.pageLoad.scroll" size="small">
                    <el-option label="25% of the page" value="25"></el-option>
                    <el-option label="50% of the page" value="50"></el-option>
                    <el-option label="75% of the page" value="75"></el-option>
                    <el-option label="100% of the page" value="100"></el-option>
                  </el-select>
                </div>

                <!-- Javscript Code -->
                <div class="cardItemBlock javascriptContainer" v-if="behaviour.when.type == 'javascript'">
                  <div class="inputLabel">Use following javascript.</div>
                  <pre style="night-owl" v-highlightjs><code style="night-owl" class="javascript">Growlytics.showMessage('{{behaviour.when.uuid}}');</code></pre>
                </div>
              </el-card>
            </el-col>

            <!-- Behaviour - Frequency  -->
            <el-col :span="12" v-if="this.campaignContent && this.campaignContent.json.appereance.type != 'embed'">
              <el-card class="box-card" shadow="never">
                <div slot="header" class="clearfix">
                  <span>Display Frequency</span>
                </div>

                <div class="cardItemBlock">
                  <div class="inputLabel">Set Frequency</div>
                  <el-select v-model="behaviour.frequency.type" placeholder="Select">
                    <el-option label="Show Only Once" value="once"></el-option>
                    <el-option label="Show Every Time" value="all_time"></el-option>
                    <el-option label="Once every hour" value="1"></el-option>
                    <el-option label="Once every 6 hours" value="6"></el-option>
                    <el-option label="Once a day" value="24"></el-option>
                    <el-option label="Once every 2 days" value="48"></el-option>
                    <el-option label="Once every 5 days" value="120"></el-option>
                    <el-option label="Once every 7 days" value="168"></el-option>
                    <el-option label="Once every 15 days" value="360"></el-option>
                    <el-option label="Once every 30 days" value="720"></el-option>
                  </el-select>
                </div>

                <div class="cardItemBlock">
                  <el-checkbox v-model="behaviour.dontShowToConverted">Do not show to converted customers.</el-checkbox>
                  <br />
                  <!-- <el-checkbox v-model="behaviour.dontShowToIdentified">Do not show to logged-in/identified customers.</el-checkbox> -->
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" src="./createWebMessageCampaign.scss"></style>

<script>
import CreateCampaignComponent from './createWebMessageCampaignComponent';
export default CreateCampaignComponent;
</script>
