<template>
  <el-drawer append-to-body custom-class="webPopupTemplateGalleryDailogBox" direction="btt" :visible.sync="visible">
    <!-- Header -->
    <div class="flex border-b bg-primary-600" slot="title">
      <div class="flex-1 my-2 leading-8 pl-3 text-white">Select Popup Template</div>

      <div>
        <i class="fa-sharp fa-regular fa-xmark text-2xl text-white mt-1.5 ml-4 mr-2.5 cursor-pointer" @click="visible = !visible" />
      </div>
    </div>

    <div class="body bg-white">
      <!-- Header -->
      <div class="flex px-3 pt-3 pb-2 border-b bg-gray-50">
        <!-- Categories -->
        <div class="flex-1">
          <el-checkbox-group v-model="selectedCategoryList" @change="populateTemplates">
            <el-checkbox border label="data-collection">Collect Email/Mobile</el-checkbox>
            <!-- <el-checkbox border value="announcement" label="Announcements"></el-checkbox> -->
            <el-checkbox border label="coupon-discount">Coupons & Offers</el-checkbox>
            <!-- <el-checkbox border label="subscribe-newsletter">NewsLetter Subscribe</el-checkbox> -->
          </el-checkbox-group>
        </div>
        <!-- Layout -->
        <div>
          <el-checkbox-group size="mini" v-model="selectedLayout" @change="populateTemplates">
            <el-checkbox-button border label="2cols">
              <el-tooltip class="item" effect="dark" content="Landscape" placement="bottom-center">
                <img class="h-6" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Landscape.jpg" />
              </el-tooltip>
            </el-checkbox-button>
            <el-checkbox-button border label="2rows">
              <el-tooltip class="item" effect="dark" content="Portrait" placement="bottom-center">
                <img class="h-6" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Portrait.jpg" />
              </el-tooltip>
            </el-checkbox-button>
            <el-checkbox-button border label="simple">
              <el-tooltip class="item" effect="dark" content="Simple" placement="bottom-center">
                <img class="h-6" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Simple.jpg" />
              </el-tooltip>
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>

      <!-- Templte List -->
      <el-row :gutter="20" class="p-4 bg-gray-100">
        <el-col :span="12" v-for="(template, index) in templateList" :key="index">
          <div class="rounded-lg overflow-hidden mb-4 bg-white shadow-sm">
            <div class="relative overflow-hidden bg-gray-200 rounded-lg w-full h-80 bg-contain bg-center" :style="{ 'background-image': `url(https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/${template.id}.png)` }">
              <div class="absolute right-2 top-2 rounded-full">
                <template v-if="template.layout == '2cols'">
                  <img class="h-4" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Landscape.jpg" />
                </template>
                <template v-else-if="template.layout == '2rows'">
                  <img class="h-4" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Portrait.jpg" />
                </template>
                <template v-else-if="template.layout == 'simple'">
                  <img class="h-4" src="https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/screenshots/Simple.jpg" />
                </template>
              </div>
              <!-- <img :src="`https://s3.ap-south-1.amazonaws.com/static.growlytics.in/on-site/templates/popup/${template.id}.png`" /> -->
            </div>
            <div class="p-2" style="border-radius: 0px 0px 5px 5px">
              <div class="flex">
                <div class="flex-1">
                  <div class="text-base font-bold">{{ template.id }} &nbsp; {{ template.title }}</div>
                  <div class="text-sm text-gray-500">{{ template.description }}</div>
                </div>
                <div class="pt-2">
                  <el-button type="primary" plain size="mini" @click="onApplyTemplate(template)"> Use Template </el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="templateContainer">
            <div class="templateItem">
              <img :src="template.image" />
            </div>
            <div class="templateItemInfo">
              <el-button type="success" size="small" @click="onApplyTemplate(template)">Use This Template</el-button>
            </div>
          </div> -->
        </el-col>
      </el-row>
    </div>
  </el-drawer>
</template>

<style lang="scss" src="./webPopupTemplateGallery.scss"></style>

<script>
import webPopupGalleryComponent from './webPopupGalleryComponent';
export default webPopupGalleryComponent;
</script>
