var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.block)?_c('div',{staticClass:"blockSection",class:{
    desktopHidden: !_vm.block.desktopVisible,
    mobileHidden: !_vm.block.mobileVisible
  },style:(_vm.getCalculatedContainerStyles()),attrs:{"id":'block_' + _vm.block.id}},[_c('div',{staticClass:"mobileShowHideDiv to-be-removed"},[(!_vm.block.desktopVisible)?_c('el-tooltip',{attrs:{"effect":"dark","content":"Hide In Desktop","placement":"bottom"}},[_c('div',{staticClass:"mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md"},[_c('i',{staticClass:"fa-regular fa-display"}),_vm._v(" "),_c('i',{staticClass:"fa-solid fa-slash"})])]):_vm._e(),_vm._v(" "),(!_vm.block.mobileVisible)?_c('el-tooltip',{attrs:{"effect":"dark","content":"Hide In Mobile","placement":"bottom"}},[_c('div',{staticClass:"mobileShowHideDiv_btn bg-secondary-600 text-white text-center rounded-md"},[_c('i',{staticClass:"fa-regular fa-mobile"}),_vm._v(" "),_c('i',{staticClass:"fa-solid fa-slash"})])]):_vm._e()],1),_vm._v(" "),(_vm.block.type == 'text')?_c('div',{staticClass:"textSection",style:({
      margin: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px '
    })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.block.content.html)}})]):_vm._e(),_vm._v(" "),(_vm.block.type == 'image')?_c('div',{staticClass:"imageSection",style:({
      margin: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px ',
      'text-align': _vm.block.content.alignment
    })},[(!_vm.block.content.src)?_c('div',{staticClass:"imagePreview"},[_c('i',{staticClass:"fa fa-picture-o"})]):(!_vm.block.content.link)?_c('img',{staticClass:"section-img",style:({
        width: _vm.block.content.width + '% !important',
        'border-radius': _vm.block.content.radius + 'px'
      }),attrs:{"src":_vm.block.content.src}}):_c('a',{attrs:{"href":_vm.block.content.link,"_target":"blank"}},[_c('img',{staticClass:"section-img",style:({
          width: _vm.block.content.width + '% !important',
          'border-radius': _vm.block.content.radius + 'px'
        }),attrs:{"src":_vm.block.content.src}})])]):_vm._e(),_vm._v(" "),(_vm.block.type == 'button')?_c('div',{staticClass:"buttonSection",style:({ 'text-align': _vm.block.content.alignment })},[(!_vm.block.content.link)?_c('div',{staticClass:"buttonStyle",class:{ 'grw-message-close': _vm.block.closeOnClick, 'grw-message-convert': _vm.block.convertOnClick },style:({
        margin: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px ',
        padding: _vm.block.content.insidePadding.top + 'px ' + _vm.block.content.insidePadding.right + 'px ' + _vm.block.content.insidePadding.bottom + 'px ' + _vm.block.content.insidePadding.left + 'px ',
        'font-size': _vm.block.content.fontSize + 'px',
        'background-color': _vm.block.content.buttonColor,
        color: _vm.block.content.textColor,
        'border-radius': _vm.block.content.radius + 'px'
      })},[_vm._v("\n      "+_vm._s(_vm.block.content.text)+"\n    ")]):_c('a',{staticClass:"buttonStyle",class:{ 'grw-message-close': _vm.block.closeOnClick, 'grw-message-convert': _vm.block.convertOnClick },style:({
        padding: _vm.block.content.insidePadding.top + 'px ' + _vm.block.content.insidePadding.right + 'px ' + _vm.block.content.insidePadding.bottom + 'px ' + _vm.block.content.insidePadding.left + 'px ',
        margin: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px ',
        'font-size': _vm.block.content.fontSize + 'px',
        'background-color': _vm.block.content.buttonColor,
        color: _vm.block.content.textColor,
        'border-radius': _vm.block.content.radius + 'px'
      }),attrs:{"href":_vm.block.content.link,"_target":"blank"}},[_vm._v("\n      "+_vm._s(_vm.block.content.text)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.block.type == 'coupon-code')?_c('div',[_c('div',{staticClass:"couponCodeSection",style:({
        margin: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px ',
        padding: _vm.block.content.insidePadding.top + 'px ' + _vm.block.content.insidePadding.right + 'px ' + _vm.block.content.insidePadding.bottom + 'px ' + _vm.block.content.insidePadding.left + 'px ',
        'background-color': _vm.block.content.backgroundColor,

        color: _vm.block.content.text.color,
        'font-size': _vm.block.content.text.fontSize + 'px',
        'font-weight': _vm.block.content.text.fontWeight,
        'letter-spacing': _vm.block.content.text.letterSpacing + 'px',

        'border-radius': _vm.block.content.radius + 'px',
        'border-style': _vm.block.content.border.type,
        'border-width': _vm.block.content.border.width + 'px',
        'border-color': _vm.block.content.border.color
      })},[_c('div',{staticStyle:{"width":"100%","display":"flex","flex-direction":"row","flex":"1 1 0%","align-items":"center","justify-content":"center"}},[_c('div',{staticStyle:{"flex":"1"},style:({ 'text-align': _vm.block.content.text.alignment, 'font-family': _vm.block.content.text.fontFamily + ' !important' })},[_vm._v(_vm._s(_vm.block.content.text.text))]),_vm._v(" "),_c('svg',{staticStyle:{"cursor":"pointer","flex-shrink":"0","display":"inline-block"},style:({ color: _vm.block.content.text.color }),attrs:{"width":"32","height":"33","viewBox":"0 0 32 33","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M3.60156 1.09961C1.94471 1.09961 0.601562 2.44275 0.601562 4.09961V22.4996C0.601562 24.1565 1.94471 25.4996 3.60156 25.4996H8V23.4996H3.60156C3.04928 23.4996 2.60156 23.0519 2.60156 22.4996V4.09961C2.60156 3.54732 3.04928 3.09961 3.60156 3.09961H18.8016C19.3538 3.09961 19.8016 3.54732 19.8016 4.09961V5.30078H21.8016V4.09961C21.8016 2.44276 20.4584 1.09961 18.8016 1.09961H3.60156Z","fill":"currentColor"}}),_vm._v(" "),_c('rect',{attrs:{"x":"11.1992","y":"8.5","width":"19.2","height":"22.4","rx":"2","stroke":"currentColor","stroke-width":"2"}})])])])]):_vm._e(),_vm._v(" "),(_vm.block.type == 'divider')?_c('div',{staticClass:"dividerSection"},[_c('div',{staticClass:"divider",style:({
        width: _vm.block.content.width + '%',
        height: _vm.block.content.height + 'px',
        'background-color': _vm.block.content.color
      })})]):_vm._e(),_vm._v(" "),(_vm.block.type == 'custom-html')?[_c('div',{staticStyle:{"padding":"0px"},attrs:{"align":"center"},domProps:{"innerHTML":_vm._s(_vm.block.content.html)}})]:_vm._e(),_vm._v(" "),(_vm.block.type == 'form')?_c('div',{staticClass:"formSection",style:({
      padding: _vm.block.content.margin.top + 'px ' + _vm.block.content.margin.right + 'px ' + _vm.block.content.margin.bottom + 'px ' + _vm.block.content.margin.left + 'px '
    })},[_vm._l((_vm.block.content.fields),function(field,fieldIndex){return _c('div',{key:fieldIndex,staticClass:"formItem",style:({ margin: 'auto', width: _vm.block.content.input.width + '%' })},[(_vm.block.content.label.visible)?_c('div',{staticClass:"fieldLabel",style:({
          'font-size': _vm.block.content.label.fontSize + 'px',
          color: _vm.block.content.label.color
        })},[_vm._v("\n        "+_vm._s(field.labelText)+_vm._s(field.required ? '*' : '')+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{attrs:{"clas":"fieldInput"}},[_c(_vm.style,{tag:"component"},[_vm._v("\n          input:focus{ outline: none; border-color: "+_vm._s(_vm.block.content.input.borderColor)+" !important; border-width: "+_vm._s(_vm.block.content.input.borderWidth + 1 + 'px')+" !important; } ::-webkit-input-placeholder { /* WebKit, Blink, Edge */ color: "+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1;} :-moz-placeholder { /* Mozilla Firefox 4 to 18 */ color: "+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1; } ::-moz-placeholder { /* Mozilla Firefox 19+ */ color: "+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1; } :-ms-input-placeholder { /* Internet Explorer 10-11 */ color: "+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1; } ::-ms-input-placeholder { /* Microsoft Edge */\n          color: "+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1; } ::placeholder { /* Most modern browsers support this now. */ color:"+_vm._s(_vm.block.content.input.placeholderColor)+"; opacity: 1; }\n        ")]),_vm._v(" "),(field.columnName == 'mobile')?_c('div',{staticClass:"mobileFormFieldContainer",style:({
            color: _vm.block.content.input.textColor,
            height: _vm.block.content.input.height + 'px',
            'background-color': _vm.block.content.input.background,
            'border-color': _vm.block.content.input.borderColor,
            'border-width': _vm.block.content.input.borderWidth + 'px',
            'border-radius': _vm.block.content.input.borderRadius + 'px'
          })},[_c('div',{staticClass:"countryFlagContainer",style:({
              height: _vm.block.content.input.height + 'px',
              'font-size': _vm.block.content.input.textSize + 'px',
              padding: _vm.block.content.input.padding + 'px',
              'padding-top': _vm.block.content.input.padding + 'px',
              'background-color': _vm.block.content.input.background,
              border: 'none !important'
            })},[_c('span',[_vm._v("+91")]),_vm._v(" "),_c('svg',{staticClass:"dropdownArrow",style:({ fill: _vm.block.content.input.textColor, 'margin-top': parseInt(_vm.block.content.input.textSize / 2) - 7 + 'px !important' }),attrs:{"height":"20","width":"20","viewBox":"0 0 20 20","aria-hidden":"true","focusable":"false","transform":""}},[_c('path',{attrs:{"d":"M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"}})])]),_vm._v(" "),_c('input',{staticClass:"grw-msg-form-input textInput mobileTextInput",style:({
              color: _vm.block.content.input.textColor,
              height: _vm.block.content.input.height + 'px',
              'font-size': _vm.block.content.input.textSize + 'px',
              padding: _vm.block.content.input.padding + 'px',
              'background-color': _vm.block.content.input.background,
              border: 'none !important'
            }),attrs:{"data-grw-prop":field.columnName,"data-grw-field":field.name,"data-grw-required":field.required,"placeholder":field.placeholder,"type":"text"}})]):[_c('input',{staticClass:"grw-msg-form-input textInput",style:({
              color: _vm.block.content.input.textColor,
              height: _vm.block.content.input.height + 'px',
              'font-size': _vm.block.content.input.textSize + 'px',
              padding: _vm.block.content.input.padding + 'px',
              'background-color': _vm.block.content.input.background,
              'border-color': _vm.block.content.input.borderColor,
              'border-width': _vm.block.content.input.borderWidth + 'px',
              'border-radius': _vm.block.content.input.borderRadius + 'px'
            }),attrs:{"data-grw-prop":field.columnName,"data-grw-field":field.name,"data-grw-required":field.required,"placeholder":field.placeholder,"type":"text"}})],_vm._v(" "),_c('div',{staticClass:"grw-msg-form-error",class:['grw-msg-form-error-' + field.columnName]}),_vm._v(" "),_c('div',{style:({ 'margin-bottom': (_vm.block.content.input.marginBottom ? _vm.block.content.input.marginBottom : 5) + 'px' })})],2)])}),_vm._v(" "),_c('div',{staticClass:"submitButtonContainer",style:({
        padding: _vm.block.content.button.padding.top + 'px ' + _vm.block.content.button.padding.right + 'px ' + _vm.block.content.button.padding.bottom + 'px ' + _vm.block.content.button.padding.left + 'px '
      })},[_c('button',{staticClass:"grw-msg-submit submitButton",style:({
          color: _vm.block.content.button.textColor,
          background: _vm.block.content.button.buttonColor,
          padding: _vm.block.content.button.textPadding.top + 'px ' + _vm.block.content.button.textPadding.right + 'px ' + _vm.block.content.button.textPadding.bottom + 'px ' + _vm.block.content.button.textPadding.left + 'px ',
          'font-size': _vm.block.content.button.fontSize + 'px',
          'border-radius': _vm.block.content.button.radius + 'px',
          width: _vm.block.content.button.widthType == 'full' ? '100%' : 'auto',
          border: _vm.block.content.button.border.width + 'px solid ' + _vm.block.content.button.border.color,
          'font-weight': _vm.block.content.button.fontWeight ? _vm.block.content.button.fontWeight : 'normal',
          'font-family': _vm.block.content.button.fontFamily ? _vm.block.content.button.fontFamily : 'inherit',
          'letter-spacing': _vm.block.content.button.letterSpacing ? _vm.block.content.button.letterSpacing + 'px' : 'normal'
        })},[_vm._v("\n        "+_vm._s(_vm.block.content.button.text)+"\n      ")])])],2):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }