import { webPopupTemplateCategories, webPopupTemplateListMaster } from './webPopupTemplateList';
import { getTemplateContent } from '@/services/webMessage';

export default {
  name: 'webPopupGallery',
  data() {
    return {
      visible: false,
      isOptional: false,

      selectedLayout: [],
      selectedCategoryList: [],
      categories: webPopupTemplateCategories,
      webPopupTemplateListMaster: webPopupTemplateListMaster,

      templateList: []
    };
  },
  methods: {
    async onApplyTemplate(template) {
      try {
        // Read contents from template
        let response = await getTemplateContent(template.id);

        let jsonContent = response.data;

        // Send content to parent
        this.$emit('templateSelected', jsonContent);

        // Close popup
        this.visible = false;
      } catch (err) {
        console.error('Failed to fetch template content', err);
        this.errorToast('Failed to apply template. Please contact support.');
      }
    },

    showDialog(isOptional) {
      this.isOptional = isOptional;
      this.visible = true;
    },

    onUseBlankTemplate() {
      this.$emit('templateSelected', null);
      this.visible = false;
    },

    populateTemplates() {
      let allTemplateIdList = JSON.parse(JSON.stringify(this.categories['all']));
      let newList = [];
      for (let i = 0; i < allTemplateIdList.length; i++) {
        let templateId = allTemplateIdList[i];
        let template = this.webPopupTemplateListMaster[templateId];
        if (template) {
          template.id = templateId;
          newList.push(template);
        }
      }

      // Add Category Filter.
      if (this.selectedCategoryList.length > 0) {
        for (let i = 0; i < newList.length; i++) {
          let template = newList[i];
          let templateId = template.id;

          let allowed = false;
          for (let j = 0; j < this.selectedCategoryList.length; j++) {
            let category = this.selectedCategoryList[j];
            if (this.categories[category].indexOf(templateId) > -1) {
              allowed = true;
              break;
            }
          }

          if (!allowed) {
            newList.splice(i, 1);
            i--;
          }
        }
      }

      // Add Layout Filter.
      if (this.selectedLayout.length > 0) {
        newList = newList.filter((template) => {
          return this.selectedLayout.indexOf(template.layout) > -1;
        });
      }

      this.templateList = newList;
    }
  },
  mounted() {
    this.populateTemplates();
  }
};
