exports.webPopupTemplateList = [
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(0,0,0,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ckf33kef9cq9i.jpeg', imageAlignment: '50% 50%', imageSize: 'cover', color: '#FFFFFF' } },
      rows: [
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="font-size:75px;color:hsl(0,75%,60%);"><strong>10% OFF</strong></span></p>', margin: { top: 20, left: 20, right: 20, bottom: 10 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1598680753989 },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="color:rgb(0,0,0);font-size:30px;">Your next order</span></p>', margin: { top: 0, left: 20, right: 20, bottom: 0 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1598680772464 },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="color:rgb(0,0,0);">Subscribe to our newsletter and get a 10% discount.</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 10 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1598680776589 },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: { top: 20, left: 50, right: 50, bottom: 44 },
            fields: [{ name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false }],
            button: { buttonColor: '#EE7E88FF', text: 'Subscribe', fontSize: 17, widthType: 'full', textColor: '#FFFFFF', radius: 0, textPadding: { top: 8, left: 8, right: 8, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
            label: { visible: true, color: '#786B6A', fontSize: 12 },
            input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 0 },
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1598680785712
        }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-3.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(0,0,0,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: 'rgba(38,205,162,1)' } },
      rows: [
        {
          type: 'text',
          name: 'Text',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png',
          mobileVisible: true,
          desktopVisible: true,
          content: { html: '<p style="text-align:center;"><span style="font-size:40px;color:#0c3d53;"><strong>Discount</strong></span></p><p style="text-align:center;"><span style="font-size:13px;color:#104a64;">Hacque adfabilitate confisus cum eadem postridie feceris, ut incognitus et repentinus, hortatore illo hesterno clientes numerando</span></p>', margin: { top: 40, left: 20, right: 20, bottom: 40 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ck9pgkev8v4gq.png' },
          id: 1599647133260
        },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: { top: 20, left: 20, right: 20, bottom: 20 },
            fields: [
              { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
              { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
            ],
            button: { buttonColor: '#0C3D53FF', text: 'SUBMIT', fontSize: 17, widthType: 'full', textColor: '#66EDBCFF', radius: 10, textPadding: { top: 8, left: 10, right: 10, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
            label: { visible: true, color: '#786B6A', fontSize: 12 },
            input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 10 },
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599647650455
        },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="color:#104a64;font-size:13px;">* Required fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599647776532 }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-1.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(247,247,247,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: 'rgba(78,163,249,1)' } },
      rows: [
        {
          type: 'columns-two-first-smaller',
          name: '2 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/right-bigger.png',
          content: {
            flex: [0.35, 0.65],
            columns: [
              [{ type: 'image', name: 'Image', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/image.png', mobileVisible: true, desktopVisible: true, content: { src: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ck9pikev9kreu.png', width: 100, alignment: 'center', radius: 0, link: null, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, margin: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599648765482 }],
              [
                { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="font-size:45px;color:#ffffff;"><strong>Smile :)</strong></span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599648793357 },
                { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="color:#ffffff;">Hacque adfabilitate&nbsp;</span><br><span style="color:#ffffff;">confisus cum eadem postridie</span></p>', margin: { top: 0, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599648808955 },
                {
                  type: 'form',
                  name: 'Form',
                  icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
                  mobileVisible: true,
                  desktopVisible: true,
                  content: {
                    width: 100,
                    margin: { top: 20, left: 20, right: 20, bottom: 20 },
                    fields: [
                      { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
                      { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
                    ],
                    button: { buttonColor: '#F1568DFF', text: 'SUBMIT', fontSize: 17, widthType: 'full', textColor: '#FFFFFF', radius: 10, textPadding: { top: 8, left: 10, right: 10, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
                    label: { visible: true, color: 'rgba(255,255,255,1)', fontSize: 12 },
                    input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 10 },
                    rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
                    rowBackgroundColor: '#FFFFFF00',
                    rowBackgroundImage: null
                  },
                  id: 1599648723787
                },
                { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="color:#ffffff;font-size:13px;">* Required fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599648895846 }
              ]
            ],
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            margin: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599648665475
        }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-7.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(0,0,0,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 0, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: '#FFFFFF' } },
      rows: [
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="color:hsl(0,0%,100%);font-size:35px;"><strong>Delicious</strong></span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#F15052FF', rowBackgroundImage: null }, id: 1599635345958 },
        {
          type: 'columns-two-first-smaller',
          name: '2 Columns',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/right-bigger.png',
          content: {
            flex: [0.35, 0.65],
            columns: [
              [
                { type: 'image', name: 'Image', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/image.png', mobileVisible: true, desktopVisible: true, content: { src: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ck9pgkev1y4vm.jpeg', width: 100, alignment: 'center', radius: 0, link: null, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, margin: { top: 0, left: 10, right: 10, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599635937273 },
                { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="font-size:13px;"><strong>Hacque adfabilitate confisus cum&nbsp;</strong></span></p>', margin: { top: 20, left: 20, right: 20, bottom: 25 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599635971217 }
              ],
              [
                {
                  type: 'form',
                  name: 'Form',
                  icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
                  mobileVisible: true,
                  desktopVisible: true,
                  content: {
                    width: 100,
                    margin: { top: 50, left: 20, right: 20, bottom: 20 },
                    fields: [
                      { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
                      { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
                    ],
                    button: { buttonColor: '#5ECE77FF', text: 'Submit', fontSize: 17, widthType: 'full', textColor: '#FFFFFF', radius: 6, textPadding: { top: 8, left: 10, right: 10, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
                    label: { visible: true, color: '#786B6A', fontSize: 12 },
                    input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 6 },
                    rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
                    rowBackgroundColor: '#FFFFFF00',
                    rowBackgroundImage: null
                  },
                  id: 1599635502762
                },
                { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:right;"><span style="font-size:13px;">* Required fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599636169476 }
              ]
            ],
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            margin: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599635354726
        }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-4.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Metro Politon',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(195,195,195,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: 'rgba(246,167,47,1)' } },
      rows: [
        { type: 'image', name: 'Image', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/image.png', mobileVisible: true, desktopVisible: true, content: { src: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ck9pgkev2q5yc.png', width: 100, alignment: 'center', radius: 0, link: null, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, margin: { top: 30, left: 0, right: 0, bottom: 10 }, rowBackgroundColor: '#F6A72FFF', rowBackgroundImage: null }, id: 1599637256990 },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="font-size:40px;color:#ffffff;"><strong>Metro</strong></span><span style="font-size:40px;color:hsl(30,75%,60%);"><strong>politan</strong></span></p>', margin: { top: 5, left: 20, right: 20, bottom: 30 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#F6A72FFF', rowBackgroundImage: null }, id: 1599637418387 },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: { top: 20, left: 20, right: 20, bottom: 20 },
            fields: [
              { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
              { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
            ],
            button: { buttonColor: '#FFFFFFFF', text: 'SUBMIT', fontSize: 17, widthType: 'full', textColor: '#F6A72FFF', radius: 10, textPadding: { top: 8, left: 10, right: 10, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
            label: { visible: true, color: 'rgba(255,255,255,1)', fontSize: 14 },
            input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 0 },
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599641583632
        },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="color:#ffffff;font-size:13px;">* Requires fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599641974562 }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-5.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(0,0,0,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: '#FFFFFF' } },
      rows: [
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="font-size:45px;color:#ffffff;">Corporate</span></p><p style="text-align:center;"><span style="color:#ffffff;">Hacque adfabilitate confisus cum eadem</span></p>', margin: { top: 40, left: 20, right: 20, bottom: 50 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: 'https://img.growlytics.in/1xh60ckfhpkage6lux_production/1xh60ck9pgkev7xyb1.png' }, id: 1599645977852 },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: { top: 20, left: 20, right: 20, bottom: 20 },
            fields: [
              { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
              { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
            ],
            button: { buttonColor: '#73A4DFFF', text: 'SUBMIT', fontSize: 17, widthType: 'full', textColor: '#FFFFFF', radius: 10, textPadding: { top: 8, left: 8, right: 8, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
            label: { visible: true, color: 'rgba(123,154,228,1)', fontSize: 15 },
            input: { background: '#FFFFFF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#7B9AE4FF', borderWidth: 1, borderRadius: 10 },
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599646147720
        },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:center;"><span style="font-size:13px;">* Required fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599646984457 }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-6.png'
  },
  {
    id: '1xh60cka2xkbqe6zvy',
    name: 'Food Template',
    type: 'email,dragAndDrop',
    json: {
      appereance: { overlayColor: 'rgba(0,0,0,0.34)', width: 550, closeButtonColor: 'rgba(92,92,92,1)', borderRadius: 10, background: { image: '', imageAlignment: '50% 50%', imageSize: 'cover', color: '#FFFFFF' } },
      rows: [
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="font-size:40px;color:#3f3645;">High</span><span style="font-size:40px;color:hsl(270,75%,60%);">Light</span></p>', margin: { top: 30, left: 20, right: 20, bottom: 0 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599636701920 },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p><span style="color:#7a7b7b;">Hacque adfabilitate confisus cum eadem postridie feceris...</span></p>', margin: { top: 10, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599636709449 },
        {
          type: 'form',
          name: 'Form',
          icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/divider.png',
          mobileVisible: true,
          desktopVisible: true,
          content: {
            width: 100,
            margin: { top: 20, left: 20, right: 20, bottom: 20 },
            fields: [
              { name: 'Email', type: 'text', columnName: 'email', placeholder: 'Enter Email', labelText: 'Email', required: true, editMode: false },
              { name: 'Mobile', columnName: 'mobile', type: 'string', placeholder: 'Enter Mobile', label: 'Enter Mobile', required: true, editMode: false, labelText: 'Mobile' }
            ],
            button: { buttonColor: '#B771FBFF', text: 'Validate', fontSize: 17, widthType: 'full', textColor: '#FFFFFF', radius: 6, textPadding: { top: 8, left: 10, right: 10, bottom: 8 }, padding: { top: 5, left: 0, right: 0, bottom: 5 }, border: { width: 0, color: '#000000' } },
            label: { visible: true, color: '#786B6A', fontSize: 14 },
            input: { background: '#F9F9F9FF', width: 100, height: 40, padding: 10, textSize: 14, textColor: '#4F4F4FFF', placeholderColor: '#A1A1A1FF', borderColor: '#A1A1A1FF', borderWidth: 1, borderRadius: 6 },
            rowPadding: { top: 0, left: 0, right: 0, bottom: 0 },
            rowBackgroundColor: '#FFFFFF00',
            rowBackgroundImage: null
          },
          id: 1599636716228
        },
        { type: 'text', name: 'Text', icon: 'https://static.growlytics.in/dashboard-assets/assets/img/email_editor/text.png', mobileVisible: true, desktopVisible: true, content: { html: '<p style="text-align:right;"><span style="font-size:13px;">* Required fields</span></p>', margin: { top: 20, left: 20, right: 20, bottom: 20 }, rowPadding: { top: 0, left: 0, right: 0, bottom: 0 }, rowBackgroundColor: '#FFFFFF00', rowBackgroundImage: null }, id: 1599636721337 }
      ]
    },
    image: 'https://static.growlytics.in/on-site/web-popup/templates/image-2.png'
  }
];

exports.webPopupTemplateListMaster = {
  1: {
    title: 'Summer Deals',
    description: 'Update your customers on seasonal deals.',
    layout: '2cols'
  },
  2: {
    title: 'Sign Up for 10%',
    description: 'Grow your contact list and share discounts with your customers.',
    layout: '2cols'
  },
  3: {
    title: 'Weekly Newsletter',
    description: 'Engage with your customers without hurting email conversions.',
    layout: '2cols'
  },
  4: {
    title: 'Happy Birthday!',
    description: 'Do something special for your customers on their birthday.',
    layout: '2cols'
  },
  5: {
    title: 'Save $15!',
    description: 'Give your customers an extra $15 off on subscription.',
    layout: '2cols'
  },
  6: {
    title: '$15 Discount!',
    description: 'Offer $15 off to your customers on their first order.',
    layout: '2cols'
  },
  7: {
    title: 'Limited Time 10% off',
    description: 'Treat your customers to 10% off for a limited period.',
    layout: '2cols'
  },
  8: { title: 'Unlock 15% off!', description: 'Surprise 15%off for your customers on subscription.', layout: '2cols' },
  9: { title: 'Join the Club', description: 'Share exclusive updates with Club members.', layout: '2cols' },
  10: { title: 'Exclusive Rewards!', description: 'Special rewards for those special customers.', layout: '2cols' },

  11: { title: 'Rewards! Rewards!', description: 'Reward your customers with every purchase.', layout: '2cols' },
  12: { title: 'Exclusive Deals ', description: 'Members of the Elite Club get updates on exclusive deals and launches.', layout: '2cols' },
  13: { title: 'Save 50%', description: 'Offer a massive discount to customers on their next purchase.', layout: '2cols' },
  14: { title: `It's your birthday!`, description: 'Celebrate your customers with something special.', layout: '2rows' },
  15: { title: 'Early Access to Deals', description: 'Early bird updates for exclsuive customers.', layout: '2rows' },
  16: { title: 'Limited Period Offer', description: 'Surprise your customers with a limited period 20% discount.', layout: '2rows' },
  17: { title: 'New Arrivals', description: 'Notify your customers as soon as a product drops.', layout: '2rows' },
  18: { title: 'Latest Deals', description: 'V.I.P. access for your customers.', layout: '2cols' },
  19: { title: 'Special Deals', description: 'Keep your customers updated on special deals.', layout: 'simple' },
  20: { title: 'Deals for You!', description: 'Hot deals for exclusive customers.', layout: 'simple' },
  21: { title: 'Sign Up for Discount', description: 'Offer a special discount to customers who sign up.', layout: 'simple' },
  22: { title: 'Unlock Special Discount', description: 'Extra 15% off for your loyal customers.', layout: 'simple' },
  23: { title: 'Back in Stock', description: 'Let your customers know ASAP!', layout: 'simple' }
};

exports.webPopupTemplateCategories = {
  all: [7, 5, 17, 10, 9, 18, 2, 22, 4, 14, 21, 8, 3, 20, 19, 6, 16, 12, 15],
  'data-collection': [7, 5, 17, 10, 9, 18, 2, 22, 4, 14, 21, 8, 3, 20, 19, 6, 16, 12, 15],
  'coupon-discount': [2, 5, 6, 7, 8, 10, 14, 16, 22],
  announcements: []
};
