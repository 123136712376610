import WebPopupBuilder from '../../components/campaign/contentBuilder/webPopup/editor/webPopupEditor';
import WebPopupTemplatesPopup from '../../components/campaign/contentBuilder/webPopup/templateGallery/webPopupTemplateGallery';
import webMessageModule from '../../services/webMessage';
const moment = require('moment');
import { getSegmentList } from '@/services/sessionFilter';
import { getAllStaticSegments } from '@/services/staticSegment';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import WebMessagePreviewPopup from './webMessagePreviewPopup';

export default {
  data() {
    return {
      // Web Popup Message Types
      message: null,
      fetchingDataOnEditMode: true,

      showSegmentFilterTo: [
        '1xh60ck8qqkj18y4yu', // Gynoveda
        '5ab586yfldih5nwn' // Arata
      ],

      // Step Variables
      stepList: ['Popup Appereance', 'Popup Behaviour'],
      currentStep: 0,

      // Step 1: Editor Variables
      campaignContent: null,

      // Step 2- behaviour
      behaviour: {
        page: {
          type: 'all', //all,specific
          pageList: []
        },
        domain: {
          type: 'all', //all,specific
          domainList: []
        },
        when: {
          type: 'pageLoad', //pageLoad,pageExit,javascript
          pageLoad: {
            showType: 'timer', // timer,scroll
            timer: '0',
            scroll: '25'
          },
          uuid: moment().valueOf().toString()
        },
        frequency: {
          type: '24'
        },
        deviceVisiblity: { mobile: true, desktop: true },
        visitorType: 'all',
        dontShowToConverted: true,
        dontShowToIdentified: true
      },
      //Step 2: Behaviour input pages
      pageInput: {
        filter: 'contains',
        value: ''
      },
      domainInput: '',
      // Step 2: Target Audience Variables
      targetAudiance: {
        audiance_type: 'all',
        segment_id: null,
        static_segment_id: null,
        filter: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
      },
      segmentList: null,
      staticSegmentList: null,
      targetAudianceStepErrors: [],

      // Save/Publish variables
      savingCampaign: false,
      launchingCampaign: false,
      deletingCampaign: false,
      savingCampaignAsDraft: false
    };
  },
  components: {
    WebPopupBuilder,
    WebPopupTemplatesPopup,
    UserPropertyFilterComponent,
    WebMessagePreviewPopup
  },
  computed: {},
  methods: {
    //#region Start: step-1 (designer) methods

    onPopupTemplateSelected(json) {
      this.$refs.webPopupBuilder.renderContent(this.message.id, this.message.messageType, { json: json });
    },

    showTemplatesDialog(isOptional) {
      this.$refs.webTemplatesPopup.showDialog(isOptional);
    },

    async readCampaignCotents() {
      this.campaignContent = await this.$refs.webPopupBuilder.getProcessedContents();
    },

    async showDesignPreview() {
      // If Its embed, preview is not abilable.
      if (this.message.messageType == 'embed') {
        this.warningToast('Preview is not avilable for embeded displays.');
        return;
      }
      await this.readCampaignCotents();
      this.$refs.webMessagePreview.showPreviewPopup(this.campaignContent.json);
    },

    async showBehaviourStep() {
      await this.readCampaignCotents();
      this.currentStep = 1;
    },

    //#endregion End: step-1 (designer) methods

    //#region Start: step-2 (behaviour) methods

    async onAddPage() {
      if (this.pageInput.value) {
        this.behaviour.page.pageList.push({
          value: this.pageInput.value,
          filter: this.pageInput.filter,
          filterDisplayText: this.pageInput.filter.replace('_', ' ')
        });
      }
      this.pageInput.value = '';
    },

    async removePage(index) {
      this.behaviour.page.pageList.splice(index, 1);
    },

    async onAddDomain() {
      if (this.domainInput) {
        this.behaviour.domain.domainList.push(this.domainInput);
      }
      this.domainInput = '';
    },

    async removeDomain(index) {
      this.behaviour.domain.domainList.splice(index, 1);
    },

    async showPageNameInput() {
      this.pageInputVisible = true;
      this.$nextTick(() => {
        this.$refs.savePageNameInput.$refs.input.focus();
      });
    },

    validateAndReadTargetAudiance() {
      // clear existing errors.
      this.targetAudianceStepErrors = [];

      // Read filters
      if (this.targetAudiance.audiance_type == 'custom') {
        let segmentCriteria = this.$refs.userFilter.getFilters();
        if (!segmentCriteria) {
          this.targetAudianceStepErrors.push('Please specify criteria.');
        } else {
          this.targetAudiance.filter = segmentCriteria;
          this.targetAudiance.segment_id = null;
        }
      } else if (this.targetAudiance.audiance_type == 'segment') {
        if (!this.targetAudiance.segment_id) {
          this.targetAudianceStepErrors.push('Please select segment for target audience.');
        } else {
          this.targetAudiance.filter = null;
          this.targetAudiance.static_segment_id = null;
        }
      } else if (this.targetAudiance.audiance_type == 'static_list') {
        if (!this.targetAudiance.static_segment_id) {
          this.targetAudianceStepErrors.push('Please select static list for target audience.');
        } else {
          this.targetAudiance.filter = null;
          this.targetAudiance.segment_id = null;
        }
      } else {
        this.targetAudiance.filter = null;
        this.targetAudiance.segment_id = null;
        this.targetAudiance.static_segment_id = null;
      }
    },

    //#endregion End: step-2 (behaviour) methods

    //#region Start: ----------- Save/Publish methods

    doValidationForSave() {
      // Validate target audiance.
      this.validateAndReadTargetAudiance();
      if (this.targetAudianceStepErrors.length > 0) {
        //Show messages as well.
        if (this.targetAudianceStepErrors.length > 0) {
          this.errorToast(this.targetAudianceStepErrors[0]);
        }
        return false;
      }
      return true;
    },

    async onSaveChanges() {
      try {
        // Read Popup Content
        await this.readCampaignCotents();

        // Validate target audiance
        let isValid = this.doValidationForSave();
        if (!isValid) return;

        this.savingCampaignAsDraft = true;
        // Prepare input
        let params = {
          targetAudiance: this.targetAudiance.audiance_type == 'all' ? null : this.targetAudiance,
          behaviour: this.behaviour,
          name: this.message.name,
          content: this.campaignContent,
          status: this.message.status
        };

        await webMessageModule.update(this.message.id, params, this);

        this.successToast('Changes Saved.');
        this.savingCampaignAsDraft = false;
      } catch (err) {
        this.savingCampaignAsDraft = false;
        this.errorToast('Failed to save changes. Please contact support.');
        this.reportError(err);
      }
    },

    async onPublish() {
      try {
        // Read popup content.
        await this.readCampaignCotents();

        // Validate target audiance
        let isValid = this.doValidationForSave();
        if (!isValid) return;

        this.savingCampaignAsDraft = true;
        // Prepare input
        let params = {
          name: this.message.name,
          targetAudiance: this.targetAudiance.audiance_type == 'all' ? null : this.targetAudiance,
          behaviour: this.behaviour,
          content: this.campaignContent,
          status: 'running'
        };

        await webMessageModule.update(this.message.id, params, this);
        this.message.status = 'running';

        this.successToast('Published successfully.');

        this.$router.push(`/webMessage/${this.message.id}/report`);
      } catch (err) {
        this.savingCampaignAsDraft = false;
        this.errorToast('Failed to publish. Please contact support.');
        this.reportError(err);
      }
    },

    // delete webMessage
    deleteWebMessage() {
      this.$swal({
        title: `Delete?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(() => {
        this.deletingCampaign = true;
        setTimeout(async () => {
          try {
            await webMessageModule.delete(this.message.id, this);
            this.$router.push({
              path: `/webMessage/${this.message.messageType}`
            });
            this.successToast(`Deleted successfully.`);
          } catch (e) {
            this.reportError(e);
            this.errorToast(`Something went wrong.`);
            this.deletingCampaign = false;
          }
        }, 50);
      });
    },

    // Clone webMessage
    async cloneWebMessage() {
      try {
        await this.$swal({
          title: 'Create Copy?',
          text: 'A new popup will be created with the same settings mentioned for this popup.',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#1a535c',
          confirmButtonText: 'Create Copy'
        });
      } catch (err) {
        // Cacelled, Ignore
        return;
      }

      try {
        this.savingCampaignAsDraft = true;
        let response = await webMessageModule.clone(this.message.id, this);

        // Redirect to campaign report page.
        this.successToast(`Duplicate popup created successfully.`);
        location.href = `/webMessage/${response.data}/edit`;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Something went wrong.`);
      } finally {
        this.savingCampaignAsDraft = false;
      }
    },

    //#endregion End: ----------- Save/Publish methods

    //#region Start: Fetch Details For Edit

    async fetchCampaignDetails(messageId) {
      this.fetchingDataOnEditMode = true;
      let response = await webMessageModule.getDetails(messageId, this);
      this.message = response.data.message;

      // Read target audiance
      if (response.data.audiance) {
        this.targetAudiance = response.data.audiance;
        delete this.targetAudiance.web_message_id;
        if (this.targetAudiance.filter) {
          this.targetAudiance.filter = JSON.parse(this.targetAudiance.filter);
        }
      }

      // Set contnet and behaviour if found
      if (response.data.content) {
        // Read behaviour
        if (response.data.content.behaviour) {
          this.behaviour = this.overrideJson(this.behaviour, JSON.parse(response.data.content.behaviour));
        }

        if (response.data.content.json) {
          requestAnimationFrame(() => {
            this.$refs.webPopupBuilder.renderContent(this.message.id, this.message.messageType, response.data.content);
          });
        }
      } else {
        // Show popup here....
        requestAnimationFrame(() => {
          this.showTemplatesDialog(false);
        });
      }

      this.fetchingDataOnEditMode = false;

      this.fetchSegmentList();
      this.fetchStaticSegmentList();
    },

    async fetchSegmentList() {
      if (this.segmentList != null) return;
      try {
        let result = await getSegmentList(true);
        let list = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].is_deleted) {
            if (result.data[i].id == this.targetAudiance.segment_id) {
              list.push(result.data[i]);
            }
          } else {
            list.push(result.data[i]);
          }
        }
        this.segmentList = list;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Failed to fetch segments. Something went wrong.`);
      }
    },

    async fetchStaticSegmentList() {
      if (this.staticSegmentList != null) return;
      try {
        let result = await getAllStaticSegments(true);
        let list = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].is_deleted) {
            if (result.data[i].id == this.targetAudiance.static_segment_id) {
              list.push(result.data[i]);
            }
          } else {
            list.push(result.data[i]);
          }
        }
        this.staticSegmentList = list;
      } catch (e) {
        this.reportError(e);
        this.errorToast(`Failed to fetch segments. Something went wrong.`);
      }
    }

    //#endregion End: Fetch Details For Edit
  },

  mounted() {
    let messageId = this.$route.params.messageId;

    this.fetchCampaignDetails(messageId);

    this.beforeUnloadConfirmFunction = function (event) {
      event.returnValue = 'Your custom message.';
    };
    window.addEventListener('beforeunload', this.beforeUnloadConfirmFunction);
    document.getElementById('subSideMenu').style.display = 'none';
  },

  beforeRouteLeave(to, from, next) {
    if (to.fullPath.startsWith('/webMessage')) {
      return next();
    }
    const answer = window.confirm('Changes that you made may not be saved!!');
    if (answer) {
      next();
    } else {
      next(false);
    }
  },

  beforeDestroy() {
    document.getElementById('subSideMenu').style.display = 'block';
    window.removeEventListener('beforeunload', this.beforeUnloadConfirmFunction);
  }
};
