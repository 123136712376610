const axios = require('axios');
const { baseUrlNew, buildAuthHeaders } = require('./settings.js');

exports.getAllStaticSegments = function (getArchived) {
  let path = `staticSegment`;
  if (getArchived) path += '?archived=1';
  return axios.get(baseUrlNew + path, {
    headers: buildAuthHeaders()
  });
};

exports.getStaticSegmentDetail = function (segmentId) {
  return axios.get(baseUrlNew + `staticSegment/${segmentId}`, {
    headers: buildAuthHeaders()
  });
};

exports.getStaticSegmentSize = function (segmentId) {
  return axios.get(baseUrlNew + `staticSegment/${segmentId}/size`, {
    headers: buildAuthHeaders()
  });
};

exports.getStaticSegmentReachablity = function (segmentId) {
  return axios.get(baseUrlNew + `staticSegment/${segmentId}/reachablity`, {
    headers: buildAuthHeaders()
  });
};

exports.getStaticSegmentCustomers = function (segmentId, params) {
  return axios.post(baseUrlNew + `staticSegment/${segmentId}/customers`, params, {
    headers: buildAuthHeaders()
  });
};

exports.exportStaticSegmentCustomers = function (segmentId, params) {
  return axios.post(baseUrlNew + `staticSegment/${segmentId}/customers/export`, params, {
    headers: buildAuthHeaders()
  });
};

exports.exportStaticSegmentCustomersV1 = function (params) {
  let path = `segment/static-export`;
  return axios.post(baseUrlNew + path, params, {
    headers: buildAuthHeaders()
  });
};

exports.deleteStaticSegment = function (id) {
  return axios.delete(baseUrlNew + `staticSegment/${id}`, {
    headers: buildAuthHeaders()
  });
};
